import React from "react"
import addToMailchimp from 'gatsby-plugin-mailchimp'

export default class IndexPage extends React.Component {
  state = {
      name: null,
      email: null,
  }

  _handleChange = e => {
      console.log({
          [`${e.target.name}`]: e.target.value,
      })
      this.setState({
          [`${e.target.name}`]: e.target.value,
      })
  }

  _handleSubmit = e => {
      e.preventDefault()

      console.log('submit', this.state)

      addToMailchimp(this.state.email, this.state)
          .then(({ msg, result }) => {
              console.log('msg', `${result}: ${msg}`)

              if (result !== 'success') {
                  throw msg
              }
              alert(msg)
          })
          .catch(err => {
              console.log('err', err)
              alert(err)
          })
  }

  render() {
      return (
          <div className="content">
              <h1>Birthday Card for Donald Trump</h1>
              <p>Please join us in celebrating President Donald J. Trump's 74th birthday by signing his card below before the 9:00 PM deadline on June 14, 2020.</p>
              <div className="main-form">
                  <form onSubmit={this._handleSubmit}>
                      <label>Leave a note and sign below!</label>
                      <input
                        className="form-message"
                        type="textarea"
                        palceholder="Message"
                      />
                      <input
                          type="text"
                          onChange={this._handleChange}
                          placeholder="Full Name"
                          name="name"
                      />
                      <input
                          type="email"
                          onChange={this._handleChange}
                          placeholder="Email Address"
                          name="email"
                      />
                      <br></br>
                      <input className="submit-btn" type="submit" />
                  </form>
              </div>
          </div>
      )
  }
}